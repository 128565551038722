.App {
  max-width: 1200px;
  margin: 0 auto;
}
.notloggedin_container {
  width: 300px;
  margin: 0 auto;
}
.notloggedin_title {
  color: #222;
  font-size: 34px;
}

.linkcardgroup_container {
  display: flex;
  justify-content: center;
  margin-top: 32px;

}
.NotLoggedIn_blank {
  height: 50px;
}

.collector_main_image_inner {
 max-width: 1200px;
 display: flex;
 justify-content: space-between;
 margin: 0 auto;
}

.collector_main_image_inner h1 {
  font-size: 48px;
}
.collector_main_image_inner div {
  font-size: 21px;
  line-height: 40px;
}

.collector_main_details {
  width: 600px;
  text-align: left;
  padding: 0px 64px;
}

.collector_main_image_bg {
  background: 2px 2px #0cc2;
}
.not_logged_in_color {
  background: 2px 2px #aaa5;
}
.collector_main_image {
  height: 350px;

}

.submitbutton {
  background: rgb(18, 146, 238) none repeat scroll 0% 0%;
  border: medium none;
  color: #fff;
  padding: 0px 20px !important;
  line-height: 46px;
  height: 46px !important;
  font-size: 24px;
  font-weight: 500;
  border-radius: 4px;
  transition: all 0.2s ease-in-out 0s;
  cursor: pointer;
}

.submitgreen {
  background: rgb(18, 238, 146);
}

.submitbutton:hover {
  background: rgb(48, 166, 255) none repeat scroll 0% 0%;
}

.submitgreen:hover {
  background:  rgb(18,255,146);
}

.submitbutton.true {
  background: rgb(206, 58, 56);
}
.submitbutton.true:hover {
  background: rgb(266, 58, 58);
}

.medium_size {
  width: 200px;
}

.small_size {
  min-width: 100px;
  text-align: center;
}

.fp_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .collector_main_image_bg {
    overflow: hidden;
  }
  .fpfeature_container {
    padding: 0px !important;
    width: 50% !important;
  }
}